<template>
  <div class="container">
    <content-title :nav="nav"></content-title>

    <div class="contentList">
      <h4 class="sec-title">邀请规则列表</h4>
      <div class="PERInformation-from flex jc-b">
        <el-button @click="add" type="primary">新增</el-button>
        <el-select
        v-model="ruleStatus"
        placeholder="请选择"
        @change="changeRule"
        style="width: 100px"
      >
        <el-option
          value="2"
          label="使用中"
        >
        </el-option>
        <el-option
          value="1"
          label="未使用"
        >
        </el-option>
      </el-select>
        <!-- <el-button @click="update">1</el-button> -->
      </div>

      <div class="paging">
        <el-table
          :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
          :data="recording"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 20px"
          highlight-current-row
          v-loading="is_loading"
        >
          <el-table-column
            prop="id"
            align="center"
            label="id"
          ></el-table-column>
          <el-table-column
            prop="name"
            align="center"
            label="规则名称"
          ></el-table-column>
          <el-table-column
            prop="type"
            align="center"
            label="规则类型"
          ></el-table-column>
          <el-table-column
            prop="reserved1"
            align="center"
            label="规则用户"
          ></el-table-column>
          <el-table-column
            prop="status"
            align="center"
            label="规则状态"
          ></el-table-column>
          <el-table-column
            prop="is_recommend"
            align="left"
            label="操作"
            width="300"
          >
            <template slot-scope="scope">
              <div style="display: flex; flex-wrap: wrap">
                <el-button @click="edit(scope.row)" size="small" type="success"
                  >编辑
                </el-button>
                <el-button
                v-if="scope.row.status==2"
                  @click="useRule(scope.row,1)"
                  size="small"
                  type="warning"
                  >取消规则
                </el-button>
                <el-button
                v-if="scope.row.status==1"
                  @click="useRule(scope.row,2)"
                  size="small"
                  type="success"
                  >使用规则
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <div style="height: 50px; display: flex; align-items: center">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="规则内容"
      :visible.sync="dialogVisible"
      :width="ks ? '90%' : '40%'"
    >
      <el-form :model="form">
        <el-form-item label="规则名称">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="规则类型">
          <el-input v-model="form.type" placeholder="请输入类型"></el-input>
        </el-form-item>
        <el-form-item label="规则用户">
          <el-input v-model="form.reserved1" placeholder="请输入类型"></el-input>
        </el-form-item>
        <el-form-item label="	可分配金额">
          <el-input
            v-model="form.allocable_amount"
            placeholder="请输入规则"
          ></el-input>
        </el-form-item>
        <el-form-item label="	邀请百分比">
          <el-input
            v-model="form.invitation_percentage"
            placeholder="请输入规则，如0.2"
          ></el-input>
        </el-form-item>
        <el-form-item label="	购买百分比">
          <el-input
            v-model="form.purchase_percentage"
            placeholder="请输入规则，0.8"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="审批人">
          <el-input
            v-model="form.each_master_southafrica_europe"
            placeholder="请输入规则"
          ></el-input>
        </el-form-item>
        <el-form-item label="审批人">
          <el-input
            v-model="form.each_master_else"
            placeholder="请输入规则"
          ></el-input>
        </el-form-item> -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateRule" v-if="type == 'update'"
          >修改</el-button
        >
        <el-button type="primary" @click="addRule" v-if="type == 'add'"
          >添加</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      nav: {
        firstNav: "团长管理",
        secondNav: "推荐规则",
      },
      name: "",
      status: "",
      phone: "",
      recording: [],
      currentPage: 1,
      total: 0,
      context: "",
      currefues: {},
      is_loading: true,
      dialogVisible: false,
      issearch: false,
      form: {},
      type: "add",
      ks: "",
      ruleStatus:"2",

    };
  },
  updated() {
    this.ks = window.screen.width < 768;
  },
  created() {
    this.GetList();
  },
  methods: {
    edit(row) {
      this.form = row;
      this.type = "update";
      this.dialogVisible = true;
    },
    add() {
      this.form = {};
      this.type = "add";
      this.dialogVisible = true;
    },
    updateRule() {
      let url = "user/userRecommenderEarnRole/update";
      this.$axios({
        url,
        data: { 
          id:this.form.id,
          allocable_amount:this.form.allocable_amount,
          invitation_percentage:this.form.invitation_percentage,
          name:this.form.name,
          purchase_percentage:this.form.purchase_percentage,
          type:this.form.type,
          reserved1:this.form.reserved1
         },
        method: "post",
      }).then((res) => {
        this.form = {};
        this.$message({ message: res.data.message });
        this.GetList();
        this.dialogVisible = false;
      });
    },
    addRule() {
      let url = "user/userRecommenderEarnRole/insert";
      this.$axios({
        url,
        data: {
          ...this.form,
        },
        method: "post",
      }).then((res) => {
        this.form = {};
        this.$message({ message: res.data.message });
        this.GetList();
        this.dialogVisible = false;
      });
    },
    useRule(row,status) {
      let url = "user/userRecommenderEarnRole/update";
      this.$axios({
        url,
        data: {
          id: row.id,
          status
        },
        method: "post",
      }).then((res) => {
        this.$message({ message: res.data.message });
        this.GetList();
      });
    },
    changeRule(){
      this.recording = []
      this.currentPage = 1
      this.GetList() 
    },
    GetList() {
      this.is_loading = true;
      let url = "user/userRecommenderEarnRole/queryManagerListPage";
      this.$axios({
        url,
        params: {
          pageSize: 10,
          currentPage: this.currentPage,
          status:this.ruleStatus
        },
        method: "get",
      })
        .then((res) => {
          // console.log(res.data);
          this.recording = res.data.data.rows;
          this.total = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },

    handleCurrentChange(v) {
      this.currentPage = v;
      this.GetList();
    },
  },
};
</script>
    <style >
</style>
    